.layoutWrapper {
  display: flex;
  min-height: 100vh;
}
.layoutChildren {
  margin-left: auto;
  width: calc(100% - 225px);
  padding: 2rem;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .layoutChildren {
    width: 100%;
    margin-top: 68px;
  }
}
@media screen and (max-width: 600px) {
  .layoutChildren {
   padding: 1rem;
  }
}
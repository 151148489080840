/* Header Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 40%;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 25%;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 25%;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 10%;
}

.tableHeaderItemAgents:nth-child(1),
.tableBodyClassAgents:nth-child(1) {
  width: 30%;
  padding-right: 10px;
}
.tableHeaderItemAgents:nth-child(2),
.tableBodyClassAgents:nth-child(2) {
  width: 25%;
  padding-right: 10px;
}
.tableHeaderItemAgents:nth-child(3),
.tableBodyClassAgents:nth-child(3) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItemAgents:nth-child(4),
.tableBodyClassAgents:nth-child(4) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItemAgents:nth-child(5),
.tableBodyClassAgents:nth-child(5) {
  width: 5%;
  min-width: 60px;
}
.tableHeaderClass {
  min-width: 650px;
}
.tableWrapperClass {
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
}
.statusWrap {
  display: flex;
}
.statusWrap div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.statusIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  margin-right: 1.2rem;
  margin-left: 5px;
  font-weight: 600;
}
.accepted {
  color: #26d507;
  background: #d1fdca;
}
.denied {
  color: #fd1818;
  background: #ffddbb;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 3px;
}
.pending {
  color: #f9bb09;
  background: #fff4d3;
}
.addUniBtn {
  color: var(--primary-color-blue);
  background: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 1rem;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  white-space: nowrap;
}
.addUniBtn svg {
  margin-right: 8px;
}
.addUniBtn svg path {
  fill: var(--primary-color-blue);
}
.searchFilterWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.searchInputWrapper {
  width: 100%;
  height: 36px;
  max-width: 550px;
  position: relative;
  height: 40px;
}
.searchInputWrapper > svg {
  position: absolute;
  width: 18px;
  left: 12px;
  top: 8px;
}
.searchInput {
  padding-left: 2.5rem;
  padding-right: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  margin: 0;
}
.tableExtra {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.headerSec {
  margin-bottom: 2rem;
}
.ttl {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.ttl img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 1rem;
  background: #e5eaff80;
  padding: 1px;
}
.ttl h1 {
  font-size: 24px;
}
.country {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.country img {
  width: 24px;
  margin-right: 10px;
  border: 1px solid #e5eaff50;
  width: 36px;
  margin-right: 25px;
}
.country span {
  text-transform: capitalize;
}
.website {
  color: var(--primary-color-blue);
  margin-top: 4px;
  display: block;
  font-size: 13px;
}
.nav {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 2rem;
  width: 100%;
}
.nav p {
  padding: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  width: 50%;
  border-bottom: 4px solid #e5eaff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav p span {
  background: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px 1px #5b7fff2b;
}
p.activeNav {
  border-color: var(--primary-color-blue);
}
.backBtn {
  display: flex;
  align-items: center;
  color: var(--primary-color-blue);
  margin-bottom: 2rem;
  background: none;
  border: none;
}
.backBtn svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.toggle {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.toggle > span {
  margin-left: 1rem;
}
.dropdownWrap {
  width: fit-content;
  text-align: left;
}
.editBtn {
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-bottom: auto;
  min-width: 24px;
  margin-top: -1rem;
}
.editBtn path {
  fill: var(--primary-color-blue);
}
.nationaityWrap {
  margin-top: 2rem;
}
.nationaityWrap p {
  margin-bottom: 1rem;
  font-weight: 500;
  letter-spacing: 0.15px;
  font-size: 16px;
  text-transform: capitalize;
}
.nationaityWrap > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.nationaityWrap > div span {
  border: 1px solid var(--primary-color-blue);
  padding: 3px 10px;
  border-radius: 50px;
  margin-right: 12px;
  color: var(--primary-color-blue);
  margin-bottom: 12px;
}
.commission {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.commission b {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  text-transform: capitalize;
}
.copySec {
  border-radius: 8px;
  border: 1px solid #5b7fff;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 1rem;
}
.copySec span {
  padding: 4px 12px;
}
.copySec button {
  background: #5b7fff;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 100%;
  display: block;
  padding: 6px 12px;
  display: grid;
  place-items: center;
}
.copySec button svg {
  width: 24px;
  height: auto;
}
.copyIcon path {
  fill: #fff;
}
.tickIcon path {
  stroke: #fff;
}
.langSch {
  font-weight: 400;
  font-size: 14px;
  margin-left: 6px;
}
@media screen and (max-width: 1000px) {
  .searchFilterWrapper {
    justify-content: space-between;
  }
  .searchInputWrapper {
    max-width: 500px;
    margin-right: 2rem;
  }
  .tableExtra {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 12px;
  }
  .searchInputWrapper {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

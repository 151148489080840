.tableContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-white);
  width: 100%;
}

.tableRow {
  min-width: 800px;
}

.tableContainer .tableRow:nth-child(1) {
  width: 100%;
}

.tableRowItem:first-of-type {
  width: 60%;
}
.viewBtn {
  background: transparent;
  border: none;
}
.viewBtn svg {
  width: 16px;
  margin-right: 24px;
  margin-left: 8px;
}
.viewBtn svg path {
  fill: #5b7fff;
}
.fillEye {
  display: none;
}
.viewBtn:hover .fillEye {
  display: block;
}
.viewBtn:hover .outlineEye {
  display: none;
}
.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
  background: #e5eaff80;
  padding: 1px;
}
.uniName {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.website {
  color: #5b7fff;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.subText {
  color: #8a94a6;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 2px;
}
.status {
  text-transform: capitalize;
}
.CTAWrap {
  display: flex;
  align-items: center;
  position: relative;
}
.commentCount {
  position: absolute;
  border-radius: 50%;
  background: #5b7fff;
  color: #fff;
  font-size: 80%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  margin-left: 4px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  top: -6px;
}
.commentCount:hover .commentCountDescrip {
  opacity: 1;
}
.commentCountDescrip {
  position: absolute;
  width: max-content;
  background: #fff;
  top: -28px;
  color: #5b7fff;
  padding: 4px 6px;
  border-radius: 20px;
  right: 0;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
  font-weight: 500;
  text-transform: lowercase;
  opacity: 0;
}
.editBtn {
  opacity: 0;
}
.tableRow:hover .editBtn {
  opacity: 1;
}
.editBtn path {
  fill: var(--primary-color-blue);
}
.CTAWrap {
  text-align: right;
  justify-content: flex-end;
}
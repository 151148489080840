.container {
}
.backBtn {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}
.backBtn span {
  color: var(--primary-color-blue);
  text-decoration: underline;
  font-weight: 500;
  margin-left: 8px;
}
.backBtn .svg {
  width: 22px;
}
.ttl {
  color: var(--primary-color-dark);
  font-weight: 700;
  margin-left: 8px;
  text-align: center;
  font-size: 20px;
  margin-top: 2rem;
}

/* Nav */
/* Form redesign */
.ctrlSec {
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: var(--primary-color-dark);
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.ctrlSec div {
  width: 25%;
  border-bottom: 5px solid #f3f3f3;
  padding-bottom: 1rem;
}
.ctrlSec .ctrlActive {
  border-color: var(--primary-color-blue);
}

.formWrap,
.subFormWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.formTtl {
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 18px;
  font-weight: 500;
  color: #101323;
}
.inputWrap {
  width: 32%;
}
.nextBtn {
  font-size: 14px;
  height: 44px;
  width: 120px;
  display: block;
  background: var(--primary-color-blue);
  color: #fff;
  border: none;
  border-radius: 8px;
  margin-top: 3rem;
  margin-left: auto;
}

.avatarSec {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  width: 100%;
  flex-wrap: wrap;
}
.avatar {
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  object-position: top;
  width: 60px;
  height: 60px;
  justify-content: center;
  margin-right: 1.3rem;
  background: #f5f5f5;
  padding: 4px;
}

.changeBtn {
  width: fit-content;
  font-size: 11px;
  border-radius: 6px;
  border: none;
  padding: 9px 18px;
  color: #fff !important;
  background: var(--primary-color-blue);
  margin-right: 8px;
}
.changeBtn input {
  display: none;
}
.errorMsg {
  font-size: 11px;
  color: var(--primary-color-red);
  display: block;
  margin-top: 4px;
  width: 100%;
  text-align: left;
  margin-top: 10px;
}
.errorMsg svg {
  width: 16px;
  margin-right: 4px;
}

.subFormWrap {
  position: relative;
}
.addBtn {
  height: fit-content;
  color: var(--primary-color-blue);
  background: transparent;
  border: none;
  font-weight: 500;
  padding: 6px 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
}
.addBtn svg {
  border: 1px solid var(--primary-color-blue);
  border-radius: 50%;
  background: var(--primary-color-blue);
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.addBtn svg path {
  fill: #fff;
}
.removeBtn {
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  position: absolute;
  top: 2rem;
  right: 0;
  background: none;
}
.addBtn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.formUploadWrap {
}
.formUploadWrap .formTtl {
  margin-bottom: 1rem;
}
.docsWrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 3rem 2rem;
  margin-bottom: 4rem;
}

/* Doc Upload */
.uploadWrap {
  width: 100%;
  position: relative;
}
.uploadLabel {
  font-weight: 500;
  color: #101323;
  margin-bottom: 14px;
}
.uploadWrap label {
  width: 100%;
  border: 1px dashed #8d7ed2;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}
label.emptyLabel {
  border: none;
  padding: 0;
  width: unset;
}
.uploadTxt {
  margin-bottom: 4px;
  font-weight: 500;
}
.formats {
  color: #8d7ed2;
  font-size: 10px;
  margin: 0;
}
.uploadWrap input {
  display: none;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b3a9e1;
  box-shadow: 0px 0px 0px 4px rgba(166, 97, 255, 0.16);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-bottom: 14px;
}
.icon svg {
  width: 60%;
}
.uploadSuccess {
}

.trashIcon {
  padding: 0 2px;
  width: 21px;
  cursor: pointer;
}
.uploadSuccess .uploadLabelWrap,
.uploaded .uploadLabelWrap {
  display: flex;
  background: #f5f5f5;
  border-radius: 12px;
  width: 100%;
  display: flex;
  padding: 1.7rem 1.5rem;
  align-items: flex-start;
}
.uploaded .uploadLabelWrap {
  border: 1px solid var(--green);
  background: #e9fff25c;
  padding: 1.2rem 1rem;
  /* max-width: 300px; */
  width: fit-content;
  min-width: 300px;
  border-radius: 10px;
  align-items: center;
}
.uploadSuccess .uploadLabelWrap > svg,
.uploaded .uploadLabelWrap > svg {
  min-width: 18px;
}

.uploaded .uploadLabelWrap .icon svg path {
  stroke: var(--green);
}
.uploaded .icon {
  background: #e5fff0;
  margin-bottom: 0;
}

.uploadSuccess .formFileUploadLabel,
.uploaded .formFileUploadLabel {
  text-align: left;
  background: transparent;
  padding: 0 16px;
  /* margin-top: -5px; */
  width: 100%;
}
.uploadSuccess .formFileUploadLabel p:first-child {
  color: #424242;
}

.uploaded .formFileUploadLabel p:first-child {
  color: #424242;
  font-weight: 500;
  margin: 0;
}
.uploadSuccess .formFileUploadLabel p:nth-child(2),
.uploaded .formFileUploadLabel p:nth-child(2) {
  font-size: 11px;
  color: #757575;
}
.uploadProgress {
  width: 100%;
  height: 4px;
  background: #e0e0e0;
  border-radius: 4px;
  margin: 1rem 0;
  position: relative;
}
.uploadProgress::before {
  content: "";
  display: block;
  height: 100%;
  background: var(--primary-color-blue);
  width: 10%;
  border-radius: inherit;
  animation: progress 1.5s ease-in;
  animation-fill-mode: forwards;
}
.uploaded .uploadProgress {
  display: none;
}
.disabledCtrl {
  opacity: 0.5;
  cursor: not-allowed;
}
@keyframes progress {
  0% {
    width: 10%;
  }
  50% {
    width: 30%;
  }
  100% {
    width: 100%;
  }
}

.refTtlWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.refTtlWrap .formTtl {
  margin: 0;
}
.uploadDoc {
  height: auto;
  cursor: pointer;
}

.btnGroup {
  display: flex;
  margin-left: auto;
}
.btnGroup button:first-of-type {
  color: var(--primary-color-blue);
  background: none;
  font-weight: 500;
}
.delEmptyDoc {
  position: absolute;
  right: 0;
  top: 0;
}
.docUploadIcon path {
  fill: #fff;
}
.docIcon path {
  stroke: #fff;
}
@media screen and (max-width: 1100px) {
  .inputWrap {
    width: 48%;
  }
}
@media screen and (max-width: 800px) {
  .continueBtn {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .closeBtn {
    position: absolute;
    top: 1.2rem;
    margin: 0;
    right: 2rem;
  }
  .planContainer {
    padding-top: 3rem;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
}
@media screen and (max-width: 550px) {
  .inputWrap {
    width: 100%;
  }
  .refTtlWrap .addBtn {
    font-size: 13px;
  }
  .refTtlWrap .addBtn svg {
    width: 16px;
    height: 16px;
  }
  .planInfoWrap {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .planContainer {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .closeBtn {
    top: 10px;
    right: 10px;
  }
  .priceTtl2 {
    font-size: 24px;
    margin-bottom: 2rem;
    line-height: 28px;
  }
  .priceTtl2 img {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
}

.links_container {
  list-style-type: none;
  display: flex;
  background-color: #fcfcfc;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
}

.link_Item {
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 26px;
  transition: 0.3s ease-in-out;
  border-radius: 8px 8px 0px 0px;
}

.link_Item:hover {
  background-color: rgba(238, 238, 238, 0.658);
}

.link_Item span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #111111;
}

.link_Item.active {
  background-color: var(--table-header-bg);
  border-radius: 8px 8px 0px 0px;
}

.link_Item.active::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 4px;
  background-color: var(--primary-color-blue);
}

.link_Item.active span {
  color: #111111;
}

/* SECONDARY LINK STYLES */
.links_container.secondary {
  padding: 0px;
  background: rgba(153, 153, 153, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
}

.link_Item.secondary {
  border-radius: 8px;
  padding: 17px 90px;
}

.link_Item.secondary.active {
  background: transparent;
}

.link_Item.secondary.active span {
  color: var(--primary-color-dark);
}

.link_Item.secondary.active::after {
  display: none;
}

/* Text with Image */

.links_container.textWithImage {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0px;
  background-color: #fff;
}

.link_Item.textWithImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 74px;
}
.link_Item.active.textWithImage {
  background-color: var(--table-header-bg);
  color: #fff;
}

.link_Item.textWithImage.active::after {
  display: none;
}

.link_Item.textWithImage span {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100.9%;
}

.link_Item.textWithImage.active span {
  color: #fff;
  font-weight: 600;
}

.link_Item.textWithImage:first-of-type {
  border-radius: 8px 0px 0px 8px;
}
.link_Item.textWithImage:last-of-type {
  border-radius: 0px 8px 8px 0px;
}

.backBtn {
  color: #5b7fff;
  background: transparent;
  border: none;
  text-decoration: underline;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 500;
}
.backBtn svg {
  width: 18px;
}
.header {
  padding-bottom: 1rem;
}
.title {
  margin-bottom: 12px;
  font-size: 2rem;
  margin-top: 1rem;
  text-transform: capitalize;
}
.description {
  font-size: 16px;
  line-height: 1.6;
}
.count {
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: right;
  text-decoration: underline;
  font-size: 1.5rem;
}

/* Header Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 25%;
  text-transform: capitalize;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 10%;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 15%;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 15%;
}
.tableHeaderItem:nth-child(5),
.tableBodyClass:nth-child(5) {
  width: 20%;
}
.tableHeaderItem:nth-child(6),
.tableBodyClass:nth-child(6) {
  width: 10%;
}

.tableWrapperClass {
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
}
.tableHeaderClass {
  min-width: 800px;
}

/* Header Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 5%;
  text-transform: capitalize;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 23%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 25%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 15%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(5),
.tableBodyClass:nth-child(5) {
  width: 12%;
  min-width: 60px;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(6),
.tableBodyClass:nth-child(6) {
  width: 10%;
}
.tableHeaderItem:nth-child(7),
.tableBodyClass:nth-child(7) {
  width: 10%;
}
.tableHeaderClass {
  min-width: 800px;
}
.tableWrapperClass {
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
}
.statusWrap {
  display: flex;
}
.statusWrap div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.statusIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  margin-right: 1.2rem;
  margin-left: 5px;
  font-weight: 600;
}
.accepted {
  color: #26d507;
  background: #d1fdca;
}
.denied {
  color: #fd1818;
  background: #ffddbb;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 3px;
}
.pending {
  color: #f9bb09;
  background: #fff4d3;
}
.addUniBtn {
  background: var(--primary-color-blue);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: flex-end;
  margin-top: -3rem;
}
.addUniBtn svg {
  margin-right: 8px;
}
.searchInputWrapper {
  width: 100%;
  height: 36px;
  max-width: 300px;
  position: relative;
  height: 40px;
}
.searchInputWrapper svg {
  position: absolute;
  width: 18px;
  left: 12px;
  top: 12px;
}
.searchInput {
  padding-left: 2.5rem;
  padding-right: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}
.dropdownWrap {
  width: 140px;
}
.searchFilterWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.filterWrapper {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
  }
  .searchInputWrapper {
    width: 100%;
    max-width: unset;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 12px;
  }
  .searchInputWrapper {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .filterWrapper {
    justify-content: space-between;
  }
  .filterWrapper > div {
    width: 48% !important;
  }
}

.wrapper {
  position: fixed;
  right: 0;
  width: 85vw;
  background: #fff;
  max-width: 450px;
  height: 100vh;
  top: 0;
  padding: 2rem;
  box-shadow: 0px 0 10px 1px rgb(0 0 0 / 15%);

  overflow-y: auto;
  z-index: 4;
}
.show {
  animation: slideIn 2s ease-out 0s 1 normal forwards;
}
.hide {
  animation: slideOut 2s ease-out 0s 1 normal forwards;
}
@keyframes slideIn {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}
@keyframes slideOut {
  from {
    right: 0;
  }
  to {
    right: -100vw;
  }
}
.ttl {
  margin-bottom: 1rem;
  font-size: 22px;
  font-weight: 700;
}
.txt {
  margin-bottom: 2rem;
}

.closeBtn {
  transform: rotate(45deg);
  width: 24px;
  height: 24px;
  right: 1rem;
  top: 1rem;
  position: fixed;
}
.closeBtn path {
  fill: var(--primary-color-danger);
}
.showIcon {
  animation: slideInIcon 2s ease-out 0s 1 normal forwards;
}
.hideIcon {
  animation: slideOutIcon 2s ease-out 0s 1 normal forwards;
}
@keyframes slideInIcon {
  from {
    right: -100vw;
  }
  to {
    right: 1rem;
  }
}
@keyframes slideOutIcon {
  from {
    right: 1rem;
  }
  to {
    right: -100vw;
  }
}
.submitBtn {
  margin-top: 1rem;
  width: 100%;
  background: var(--primary-color-blue);
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
}
.errorMessage {
  color: var(--primary-color-danger);
  text-align: center;
  margin-top: 1rem;
}
.tableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableRow {
  width: 100%;
  min-width: 853px;
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: unset !important;
}

/* Table row item styles */
.tableRowItem {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 10px;
  text-transform: capitalize;
}

.tableRowItem:nth-of-type(4) {
  text-transform: lowercase;
}


/* Delete CTA style */
.deleteCTA {
  align-items: center;
  display: flex;
  border-radius: 5px;
  border: none;
  text-align: center;
  background: var(--table-header-bg);
  padding: 8px 1rem;
  justify-content: center;
  margin-top: auto;
}

.deleteCTA .svg path {
  fill: var(--primary-color-dark);
}

.viewBtn {
  color: #5b7fff;
  background: transparent;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.viewBtn svg {
  width: 16px;
  margin-right: 8px;
}
.viewBtn svg path {
  fill: #5b7fff;
}
.fillEye {
  display: none;
}
.viewBtn:hover .fillEye {
  display: block;
}
.viewBtn:hover .outlineEye {
  display: none;
}
.email {
  word-break: break-all;
  white-space: normal;
  text-transform: lowercase;
}
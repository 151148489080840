.wrapper {
  position: fixed;
  right: 0;
  width: 85vw;
  background: #fff;
  max-width: 450px;
  height: 100vh;
  top: 0;
  padding: 1.5rem 1rem;
  box-shadow: 0px 0 10px 1px rgb(0 0 0 / 15%);
  overflow-y: auto;
  z-index: 1000;
}
.show {
  animation: slideIn 0.8s ease-out 0s 1 normal forwards;
}
.hide {
  animation: slideOut 0.8s ease-out 0s 1 normal forwards;
}
@keyframes slideIn {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}
@keyframes slideOut {
  from {
    right: 0;
  }
  to {
    right: -100vw;
  }
}

.formTxt {
  color: var(--primary-color-dark);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.5rem;
}
.backToApplications img {
  width: 22px;
}
.formWrap {
  padding-top: 1rem;
  height: 100%
}
.close {
  display: block;
  color: var(--primary-color-danger);
  font-size: 36px;
  line-height: 1;
  border: none;
  background: transparent;
  font-weight: 300;
  height: 32px;
  position: absolute;
  right: 1rem;
  top: 12px;
  padding: 0 4px;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #5b7fff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  margin-right: 12px;
}

/* Comments */
.commentsContainer {
  padding: 10px;
  border-radius: 8px;
  background: #fafbfc;
  width: 100%;
  position: relative;
  padding-bottom: 150px;
  height: calc(100vh - 100px);
}
.commentsContainer .formTtl {
  margin-bottom: 10px;
}
.commentScrollContanier {
  overflow: hidden auto;
  height: 100%;
  /* padding-bottom: 2rem; */
}
.commentScrollContanier .spinner {
  margin: 4rem auto;
  border-width: 6px;
  border-top: 6px solid #5b7fff;
  width: 44px;
  height: 44px;
}
.avatar {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  overflow: hidden;
}
.avatar svg {
  width: 100%;
  height: 100%;
}
.avatar div {
  font-weight: 800;
  font-size: 15px;
  color: #323b4b;
}
.commentInputWrapper {
  display: flex;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  background: #fafbfc;
  padding-top: 4px;
  margin-left: -10px;
  padding-left: 8px;
  padding-right: 8px;
  align-items: flex-end;
}
.formWrap .commentTextarea {
  width: calc(100% - 100px);
  margin-right: 8px;
  min-height: 80px;
  height: 80px;
  max-height: 130px;
  margin-bottom: 0;
  border: none;
  padding: 8px 10px;
  border-radius: 4px;
  max-height: 100px;
}
.commentSendBtn {
  width: 40px;
  height: 28px;
  border-radius: 8px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5b7fff;
  color: #fff;
  border: none;
}
.commentSendBtn svg {
  width: 40%;
}
.emptyComment {
  text-align: center;
  margin: 7rem auto;
  font-weight: 500;
  font-size: 1.5rem;
  color: #00000080;
}
.titleWrap {
  display: flex;
  justify-content: space-between;
}
.unread {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.unread p {
  font-weight: 500;
}
.unread div {
  margin-left: 10px;
  font-size: 90%;
  display: flex;
  align-items: center;
}
.unread div span {
  display: inline-flex;
  border-radius: 50%;
  background: #5b7fff;
  color: #fff;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  font-size: 90%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 500px) {
  .unread {
    overflow: hidden;
    overflow-x: auto;
  }
  .avatar {
    display: none;
  }
  .formWrap .commentTextarea {
    width: calc(100% - 50px);
  }
}

[role="button"] {
  cursor: pointer;
}

.studentInfo {
  display: flex;
  margin-bottom: 1rem;
  text-transform: capitalize;

}
.studentInfo img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  margin-right: 12px;
  border: 1px solid #8a94a630;
}
.studentInfo h2 {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
  margin: 0;
}
.studentInfo p {
  margin: 0;
}
.studentInfo p:last-of-type {
  font-size: 13px;
}
.dropdownWrap {
  width: calc(100% - 100px);
  margin-left: 44px;
}
.dropdownWrap * {
  text-align: left;
}
.dropdownWrap > span {
  margin-bottom: 4px;
}
.wrapper {
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
}
.avatar {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  overflow: hidden;
}
.avatar svg {
  width: 100%;
  height: 100%;
}
.avatar div {
  font-weight: 800;
  font-size: 15px;
  color: #323b4b;
}
.details {
  width: calc(100% - 50px);
}
.detailsTtl {
  font-size: 14px;
  color: #323b4b;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.detailsTxt {
  padding: 5px 6px;
  border: 1px solid #8a94a6;
  border-radius: 6px;
  color: #323b4b;
  font-size: 13px;
  margin-bottom: 8px;
  white-space: break-spaces;
}
.actions {
  display: flex;
}
.date {
  color: #323b4b;
  font-size: 12px;
  margin-right: 10px;
}
.actions button {
  border: none;
  background: none;
  padding: 0;
  font-size: 12px;
  text-decoration: underline;
  height: fit-content;
  margin-right: 10px;
}
.edit {
  color: #5b7fff;
}
.delete {
  color: #ed2d2d;
}

@media screen and (max-width: 600px) {
 .wrapper {
     padding-left: 0;
 }   
}
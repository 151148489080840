.tableContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-white);
  width: 100%;
}

.tableContainer .tableRow:nth-child(1) {
  width: 100%;
}
.tableRow {
  min-width: 800px;
}

.tableRowItem:first-of-type {
  width: 60%;
}

.accepted {
  color: #26d507;
  background: #d1fdca;
}
.denied,
.rejected {
  color: #fd1818;
  background: #ffddbb;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 3px;
}
.pending {
  color: #f9bb09;
  background: #fff4d3;
}
.status {
  text-align: center;
  padding: 8px 18px;
  border-radius: 6px;
  font-size: 13px;
  width: 90px;
}
.verifyBtn {
  background: #5b7fff;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 1rem;
  width: 75px;
  margin-bottom: 4px;
}
.verifyBtn:last-of-type {
  margin-bottom: 0;
}

.ttl {
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-size: 22px;
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 1.5rem;
}
.closeBtn {
  margin-left: auto;
  display: block;
  margin-bottom: -1rem;
  margin-top: -1rem;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.submitBtn {
  background: var(--primary-color-blue);
  color: var(--primary-color-white);
  border: none;
  padding: 8px 1rem;
  width: 100%;
  max-width: 200px;
  height: 36px;
  margin: 2rem auto 0 auto;
  display: block;
  border-radius: 6px;
}

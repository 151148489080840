.modalContainer {
  position: relative;
  align-items: flex-start;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23E5EAFF' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23E5EAFF'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.modalContainer h2 {
  margin-bottom: 20px;
}

.messageBody,
.messageTitle,
.messageStatus {
  margin-bottom: 16px;
  width: 100%;
}
.messageBody p {
  max-height: 150px;
  overflow-y: auto;
  padding-right: 30px;
}
.messageBody h3,
.messageTitle h3 {
  margin-bottom: 4px;
}
.date {
  margin-left: auto;
  margin-bottom: 1rem;
}
.image {
  width: 100%;
  height: 150px;
  max-width: 250px;
  border: 1px solid #f2f2f2;
  margin-bottom: 1rem;
  position: relative;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.edit .image img {
  opacity: 0.5;
}
.cameraIcon {
  position: absolute;
  width: 34px;
  left: calc(50% - 17px);
  top: calc(50% - 17px);
  z-index: 1;
}
.link {
  width: 100%;
}
.link a {
  max-width: 250px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input,
textarea {
  width: 100%;
  color: inherit;
  border: none;
  margin-top: 4px;
}
textarea {
  height: fit-content;
  min-height: 100px;
  resize: none;
}

.edit input,
.edit textarea {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding-left: 10px;
}
.edit input {
  border: 1px solid #ccc;
  height: 36px;
}
.edit textarea {
  padding-top: 10px;
}
button:disabled {
  background: rgb(105, 105, 105);
  cursor: not-allowed;
}
.message {
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  color: #5b7fff;
  font-weight: 500;
}
.message {
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  color: #ff5630;
  font-weight: 500;
}
.verificationIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  width: 20px;
  height: 20px;
}

.tickIcon {
  fill: #34f734;
}

.warningIcon {
  fill: yellow;
  stroke: red;
}

.statContainer {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
}
.statBody {
  padding: 1rem;
  box-shadow: 0px 1px 4px 1px rgb(189 197 205 / 21%);
  border-radius: 6px;
  margin-bottom: 1.5rem;
}
.heading {
  display: flex;
  font-weight: 500;
  align-items: baseline;
  justify-content: space-between;
  font-size: 16px;
}
.ttl {
  font-weight: 500;
  margin-bottom: 10px;
}
.heading .count {
  font-size: 20px;
  text-decoration: underline;
}
.doubleCharts {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
.doubleCharts p {
  margin-bottom: 12px;
  margin-top: 1rem;
}
.courses {
  border-left: 6px solid #837ddf;
  padding: 14px 1rem !important;
}
.courses .ttl {
  margin-bottom: 8px;
  font-weight: 400;
}
.courses .count {
  font-size: 20px;
  font-weight: 600;
}
.overTimeSec {
  padding: 1rem;
  box-shadow: 0px 1px 4px 1px rgb(189 197 205 / 21%);
  grid-column-end: 3;
  grid-column-start: 1;
  border-radius: 6px;
}
div.period {
  display: flex;
  box-shadow: unset !important;
  padding: 0 !important;
  align-items: flex-end;
}
.period input {
  width: 150px;
  margin-right: 1rem;
  padding-right: 10px;
  background: transparent;
  border: 1px solid;
  border-radius: 4px;
}
.period button {
  height: 36px;
  margin-bottom: 1rem;
  padding: 8px 1.5rem;
  background: var(--primary-color-blue);
  border: none;
  border-radius: 4px;
  color: #fff;
}
.actionNeeded {
  border-color: #ff0000;
}

@media screen and (max-width: 1100px) {
  .statContainer {
    grid-template-columns: 1fr;
  }
  .overTimeSec {
    grid-column-end: 2;
  }
  .statWrap2 {
    grid-row-start: 1;
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 0 1rem;
  }
}

@media screen and (max-width: 800px) {
  .statWrap2 > div {
    grid-column-end: 2;
  }
  .statWrap2 {
    grid-template-columns: 1fr;
  }
  .overTimeSec {
    display: none;
  }
  .doubleCharts {
    grid-template-columns: 1fr;
  }
  .statCount {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem
  }
}

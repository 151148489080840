.wrapper {
  position: fixed;
  right: 0;
  width: 85vw;
  background: #fff;
  max-width: 450px;
  height: 100vh;
  top: 0;
  padding: 2rem;
  box-shadow: 0px 0 10px 1px rgb(0 0 0 / 15%);

  overflow-y: auto;
  z-index: 4;
}
.show {
  animation: slideIn 2s ease-out 0s 1 normal forwards;
}
.hide {
  animation: slideOut 2s ease-out 0s 1 normal forwards;
}
@keyframes slideIn {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}
@keyframes slideOut {
  from {
    right: 0;
  }
  to {
    right: -100vw;
  }
}
.ttl {
  margin-bottom: 1rem;
  font-size: 22px;
  font-weight: 700;
}
.txt {
  margin-bottom: 2rem;
}
.form {
}
.logoWrap {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 1rem;
  position: relative;
}
.logoWrap img {
  width: 70px;
  height: 70px;
  border: 1px solid #fafbfc;
  border-radius: 50%;
  object-fit: cover;
  background: #fafbfc;
  margin-bottom: 10px;
}
.logoWrap span {
  font-size: 12px;
}
.logoWrap label {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  background: var(--primary-color-dark);
  position: absolute;
  right: 0;
  top: 53px;
  cursor: pointer;
}
.logoWrap label svg {
  width: 100%;
}
.inputWrap {
}
.submitBtn {
  margin-top: 1rem;
  width: 100%;
  background: var(--primary-color-blue);
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
}
.closeBtn {
  transform: rotate(45deg);
  width: 24px;
  height: 24px;
  right: 1rem;
  top: 1rem;
  position: fixed;
}
.closeBtn path {
  fill: var(--primary-color-danger);
}
.showIcon {
  animation: slideInIcon 2s ease-out 0s 1 normal forwards;
}
.hideIcon {
  animation: slideOutIcon 2s ease-out 0s 1 normal forwards;
}
@keyframes slideInIcon {
  from {
    right: -100vw;
  }
  to {
    right: 1rem;
  }
}
@keyframes slideOutIcon {
  from {
    right: 1rem;
  }
  to {
    right: -100vw;
  }
}
.agentList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.agent {
  border: 1px solid var(--primary-color-blue);
  border-radius: 50px;
  text-align: center;
  padding: 3px 12px;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: var(--primary-color-blue);
  width: fit-content;
  font-weight: 500;
  margin-bottom: 12px;
  margin-right: 12px;
  text-transform: capitalize;
}
.agent svg {
  transform: rotate(45deg);
  margin-left: 10px;
  margin-right: -6px;
  width: 20px;
  height: 20px;
}
.agent svg path {
  fill: var(--primary-color-danger);
}
.message {
  font-size: 12px;
  color: var(--primary-color-danger);
  display: block;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.message svg {
  width: 16px;
  margin-right: 4px;
}
.message svg path {
  fill: var(--primary-color-danger);
}
.errorMessage {
  color: var(--primary-color-danger);
  text-align: center;
  margin-top: 1rem;
}
.check {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  flex-direction: row-reverse;
  width: fit-content;
  margin-left: auto;
}
.check span {
  white-space: nowrap;
  margin-right: 1rem;
}
.check input {
  width: fit-content;
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
  transform: scale(1.3);
  accent-color: var(--primary-color-blue);
  margin: 0;
}
.bankTtl {
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 16px;
}

.tableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableRow {
  width: 100%;
  min-width: 853px;
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: unset !important;
}

/* Table row item styles */
.tableRowItem {
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-transform: capitalize;
  position: relative;
}

.tableRowItem:nth-of-type(3) {
  text-transform: lowercase;
}

/* verify CTA style */
.verifyCTA {
  align-items: center;
  display: flex;
  border-radius: 5px;
  border: none;
  text-align: center;
  background: #5b7fff;
  color: #fff;
  padding: 8px 1rem;
  justify-content: center;
  margin-top: auto;
}

.deleteCTA {
  align-items: center;
  display: flex;
  border-radius: 5px;
  border: none;
  text-align: center;
  background: var(--table-header-bg);
  padding: 8px 1rem;
  justify-content: center;
  margin-top: auto;
}

.deleteCTA .svg path {
  fill: var(--primary-color-dark);
}
.email {
  word-break: break-all;
  white-space: normal;
  text-transform: lowercase;
}
.ttl {
  font-size: 22px;
  margin-bottom: 6px;
  font-weight: 700;
  text-transform: capitalize;
}
.txt2 {
  margin-bottom: 1rem;
  display: block;
  font-size: 15px;
  cursor: pointer;
}
.txt2:hover {
  color: var(--primary-color-blue);
  text-decoration: underline;
}
.txt1 {
  line-height: 1.6;
  margin-top: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}
.txt1 span {
  font-weight: 400;
  display: inline-block;
  color: #808080;
  font-size: 12px;
}
.toggle {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}
.toggle > span {
  margin-left: 10px;
}
.verifyBtn {
  background: var(--primary-color-blue);
  color: var(--primary-color-white);
  border: none;
  padding: 8px 1rem;
  margin-left: auto;
  display: block;
  border-radius: 6px;
}
.closeBtn {
  margin-left: auto;
  display: block;
  margin-bottom: -1rem;
  margin-top: -1rem;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.editIcon path {
  fill: var(--primary-color-danger);
}

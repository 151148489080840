.tableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
}

.tableRow {
  width: 100%;
  min-width: 853px;
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: unset !important;
}

/* Table row item styles */
.tableRowItem {
  width: 33%;
  display: flex;
  align-items: center;
  z-index: 0;
  position: relative;
}

.tableRowItem button:last-of-type {
  background-color: var(--primary-color-danger);
  margin-left: 16px;
}

.tableRowItem td {
  position: relative;
  z-index: 1;
}

/* Delete CTA style */
.deleteCTA {
  align-items: center;
  display: flex;
  border-radius: 5px;
  border: none;
  text-align: center;
  background: var(--primary-color-blue);
  color: #fff;
  padding: 8px 1rem;
  justify-content: center;
  margin-top: auto;
}

.deleteCTA a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  color: #fff;
}

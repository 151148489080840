.v_saved_list_ctrl_sec {
  width: 80%;
  margin: 0 auto;
  max-width: 400px;
}
.v_saved_list_ctrl {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem 0 1.5rem;
}
.v_saved_list_ctrl_box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #b0b7c370;
  width: 12%;
  margin: 0 1%;
  aspect-ratio: 1;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  color: #323b4b;
  font-weight: 600;
}
.v_saved_list_ctrl span {
  display: flex;
}
.v_saved_list_ctrl_active {
  border: 1px solid #5b7fff;
  color: #5b7fff;
}
.v_saved_list_ctrl_end {
  background: #b0b7c350;
}
.v_saved_list_ctrl_txt {
  text-align: center;
  color: #b0b7c3;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 3rem;
}
.v_saved_list_ctrl_txt span {
  color: #8a94a6;
}

@media (max-width: 800px) {
  .v_saved_list_ctrl_txt {
    margin-bottom: 1.5rem;
    font-size: 14px;
  }
  .v_saved_list_ctrl {
    padding-top: 1.5rem;
    padding-bottom: 15px;
  }
}

.modalDialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #36363680;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.modalContent {
  background: #fff;
  border-radius: 12px;
  min-height: 50px;
  max-width: 500px;
  margin: auto;
  width: 100%;
  padding: 1rem 0;
  box-shadow: 0px 4px 12px rgb(82 82 82 / 50%);
}

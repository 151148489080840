/* Header Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 30%;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 30%;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 30%;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 10%;
}
.tableHeaderClass {
  min-width: 650px;
}
.tableWrapperClass {
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
}
.statusWrap {
  display: flex;
}
.statusWrap div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.statusIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  margin-right: 1.2rem;
  margin-left: 5px;
  font-weight: 600;
}
.accepted {
  color: #26d507;
  background: #d1fdca;
}
.denied {
  color: #fd1818;
  background: #ffddbb;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 3px;
}
.pending {
  color: #f9bb09;
  background: #fff4d3;
}

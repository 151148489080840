.selectbox_wrapper {
  width: 100%;
  position: relative;
}

/* 
============================================
SELECT DROPDOWN CONTAINER STYLES
============================================
*/

.selectbox_container {
  position: relative;
  width: 100%;
  min-width: 140px;
  padding: 0px 23px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  height: 33px;
  background: var(--table-header-bg);
  border: 1px solid rgba(18, 8, 15, 0.07);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: var(--primary-color-blue);
}

.selectbox_container.disabled {
  cursor: not-allowed;
  background: rgba(18, 8, 15, 0.03);
  opacity: 0.2;
}

.selectbox_container.resellerAccType {
  background-color: white;
}

.selectbox_container:hover {
  background-color: #99999910;
}
.selectbox_container > p {
  font-size: 14px;
  line-height: 100.9%;
  display: flex;
  align-items: center;
  color: #999999;
}

.selectbox_container_active .caret_down_icon {
  transform: rotate(180deg), translateY(-50%);
  top: 50%;
}

.caret_down_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

/* 
=======================================
RESELLER ACCOUNT
=======================================
*/

.selectedResellerAcc {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #cd7f32;
  text-transform: capitalize;
  font-weight: 400;
}

.dropdown_list_item.active .selectedResellerAcc {
  color: var(--wcp-color-melon);
  font-weight: 600;
}

.selectedResellerAcc svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

/* 
=======================================
DROPDOWN LIST
=======================================
*/

.dropdown_list {
  height: fit-content;
  max-height: 240px;
  list-style-type: none;
  position: absolute;
  top: calc(-390% - -7px);
  background: #ffffff;
  padding: 5px;
  z-index: 100;
  margin-bottom: 20px;
  box-shadow: 0px 10px 30px rgba(138, 149, 147, 0.15);
  border-radius: 10px;
  animation: scrollFromBottom 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-fill-mode: both;
  overflow: scroll;
}

.bottom {
  top: calc(100% + 7px);
}

@keyframes scrollFromBottom {
  0% {
    top: -200%;
    opacity: 0;
    right: 0;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}
@keyframes scrollFromBottomV2 {
  0% {
    top: -200%;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: calc(-310% - -7px);
  }
}

.dropdown_list_item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #12080f;
  cursor: pointer;
  opacity: 0.9;
  padding: 8px 23px;
  min-width: 150px;
  text-align: center;
}
.dropdown_list_item.active {
  color: var(--primary-color-blue);
}

.dropdown_list_item:hover {
  background-color: #99999910;
}
.selectLabel {
  font-size: 12px;
  text-align: right;
  width: 100%;
  padding-right: 12px;
  display: block;
  color: #92969c;
}
.itemTitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: inherit;
  white-space: nowrap;
}

/* 
 * =================================
 *  GENERAL TABLE STYLES
 * ==================================
 */

.mainTableContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

/* 
 * =================================
 *  TABLE HEADER STYLES
 * ==================================
 */

.generalHeaderContainer {
  display: flex;
  width: 100%;
  background-color: var(--table-header-bg);
  padding: 15px 0px 15px 0px;
  border-radius: 5px;
}

.generalHeaderContainer tr {
  height: fit-content;
}

.generalHeaderContainer tr th {
  text-align: left;
  color: #323b4b;
  font-weight: 600;
  padding-right: 1rem;
}

/* 
 * =================================
 *  TABLE ROW STYLES
 * ==================================
 */

.generalTableRow {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.adminTableBody tr {
  /* height: 50px; */
  border-bottom: var(--table-row-border);
  padding-top: 8px;
  padding-bottom: 8px;
}
.container {
  display: inline-block;
  position: relative;
}

.container ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.header {
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 8px 10px;
  border: 1px solid #b0b7c3;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}
.header > div {
  display: flex;
}
.header svg {
  margin: 0px 5px;
  width: 20px;
  height: 20px;
}

.list {
  /* position: relative; */
  position: absolute;
  z-index: 10;
  margin-top: 20px;
  left: 50px;
  border: 1px solid rgba(9, 30, 66, 0.15);
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 18px 28px 0px rgba(9, 30, 66, 0.15);
}

.list ul {
  padding: 10px;
}

.list button {
  background: transparent;
  border: 1px solid #b0b7c3;
  border-radius: 9px;
  margin: 8px 0px;
  font-weight: 600;
  padding: 8px 10px;
  font-size: 12px;
  width: 100%;
}

.accepted,
.accepted * {
  fill: #ffab00;
  color: #ffab00;
}
.rejected,
.rejected *,
.unconfirmed,
.confirmed * {
  fill: #ff5630;
  color: #ff5630;
}
.submitted,
.submitted * {
  fill: #4e5d78;
  color: #4e5d78;
}
.admission,
.admission * {
  fill: #4b369d;
  color: #4b369d;
}
.payable,
.payable * {
  fill: rgba(176, 50, 94, 1);
  color: rgba(176, 50, 94, 1);
}
.confirmed,
.confirmed *, .sent, .sent * {
  fill: #38cb89;
  color: #38cb89;
}
.new,
.new * {
  fill: #b0b7c3;
  color: #b0b7c3;
}
.paid,
.paid * {
  fill: #323b4b;
  color: #323b4b;
}
.review,
.review * {
  fill: #5b7fff;
  color: #5b7fff;
}
.default,
.default * {
  fill: #b0b7c3;
  color: #b0b7c3;
}

.statusListDetail {
  display: none;
}
.statusListItem:hover {
  position: relative;
}
.statusListItem:hover > .statusListDetail {
  display: block;
}

.sidebarWrapper {
  width: 225px;
  padding: 2rem 1rem 1rem;
  background: #e5eaff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  position: fixed;
}
img.logo {
  width: 81px;
  height: 50px;
  object-fit: contain;
  object-position: left;
}
.sidebarItemWrapper {
  margin: 1.5rem -4px 2rem;
  overflow: auto;
  padding: 10px 4px 0;
}
.sidebarItemWrapper::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
.sidebarItem {
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-bottom: 10px;
}
.sidebarItem:hover {
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgb(161 175 199);
}
.sidebarItem svg {
  margin-right: 1rem;
}
.sidebarItem p {
  margin-top: 12px;
  margin-bottom: 12px;
}
.sidebarItemActive {
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgb(161 175 199);
}
.logoutBtn {
  align-items: center;
  display: flex;
  border-radius: 10px;
  border: none;
  text-align: center;
  background: #ff5630;
  color: #fff;
  padding: 8px 1rem;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 1rem;
}
.logoutBtn svg {
  margin-right: 12px;
}
.mobileNav {
  display: none;
}
.desktoplogo {
  min-height: 32px;
}
@media screen and (max-width: 800px) {
  .sidebarWrapper {
    height: 100vh;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 16px;
    width: 80%;
    min-width: 300px;
    max-width: 400px;
    z-index: 2;
  }
  img.logo {
    height: 46px;
    width: 74px;
  }
  .hide,
  .desktoplogo {
    opacity: 0;
    z-index: -1;
  }
  .show {
    opacity: 1;
  }
  .mobileNav {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem 1rem;
    position: fixed;
    width: 100%;
    background: #e5eaff;
    align-items: center;
    padding: 1.2rem 2rem;
    z-index: 4;
  }
  .mobileNavReducePadding {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .hamburger {
    width: 24px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
  }
  .line {
    height: 2px;
    background: #5b7fff;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .mobileNav,
  .show {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

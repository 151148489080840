.ttl {
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.txt1 {
  line-height: 1.6;
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.txt1 span {
  font-weight: 400;
  margin-right: 10px;
  display: inline-block;
  width: 100px;
}
.txt2 {
  line-height: 1.6;
  margin-bottom: 1rem;
  white-space: break-spaces;
}
.toggle {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}
.toggle > span {
  margin-left: 10px;
}
.closeBtn {
  background: var(--primary-color-danger);
  color: var(--primary-color-white);
  border: none;
  padding: 8px 1rem;
  margin-left: auto;
  display: block;
  border-radius: 6px;
}
.editIcon {
  margin-left: auto;
  display: block;
  margin-bottom: -1rem;
  margin-top: -1rem;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.editIcon path {
  fill: var(--primary-color-blue)
}
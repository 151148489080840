.tableContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-white);
  width: 100%;
}

.tableRow {
  min-width: 650px;
}

.tableContainer .tableRow:nth-child(1) {
  width: 100%;
}

.tableRowItem:first-of-type {
  width: 60%;
}
.viewBtn {
  color: #5b7fff;
  background: transparent;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.viewBtn svg {
  width: 16px;
  margin-right: 8px;
}
.viewBtn svg path {
  fill: #5b7fff;
}
.fillEye {
  display: none;
}
.viewBtn:hover .fillEye {
  display: block;
}
.viewBtn:hover .outlineEye {
  display: none;
}
.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
  background: #e5eaff80;
  padding: 1px;
}
.uniName {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.website {
  color: #5b7fff;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

/* Modal Container */
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;
}

.modalContainer.verifiedAgents {
  align-items: flex-start;
}

.modalContainer h2 {
  text-align: center;
  font-weight: 600;
  line-height: 1.3;
}

.modalContainer.verifiedAgents h3 {
  margin-bottom: 4px;
}
.modalContainer.verifiedAgents p {
  margin-bottom: 16px;
}

.modalContainer h2 span {
  color: #ff5630;
  font-style: italic;
}

.modalBtnContainer {
  display: flex;
  margin-top: 30px;
}

.cancelBtn,
.deleteBtn {
  align-items: center;
  display: flex;
  border-radius: 5px;
  border: none;
  text-align: center;
  color: #fff;
  background: #ff5630;
  padding: 8px 1rem;
  justify-content: center;
  margin-top: auto;
}

button.deleteBtn {
  margin-right: 7.5px;
}
button.cancelBtn {
  background-color: #5b7fff;
  margin-left: 7.5px;
}

/* Error Content */

.errorContainer {
  margin-top: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorContainer h3 {
  max-width: 600px;
  text-align: center;
}

.errorContainer button {
  margin-top: 20px;
}

/* Empty Table Container */
.emptyTableContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

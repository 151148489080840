/* Table styles */

.tableWrapperClass {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.tableContainerClass {
  width: 100%;
}

.tableHeaderClass {
  min-width: 853px;
}

.tableHeaderItem {
  display: flex;
  align-items: center;
}
/* Table Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 4%;
  text-transform: capitalize;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(5),
.tableBodyClass:nth-child(5) {
  width: 15%;
  min-width: 60px;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(6),
.tableBodyClass:nth-child(6) {
  width: 12%;
}
.tableHeaderItem:nth-child(7),
.tableBodyClass:nth-child(7) {
  width: 9%;
  align-items: flex-end;
}

.nav {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 2rem;
  width: 100%;
}
.nav p {
  padding: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  width: 50%;
  border-bottom: 4px solid #e5eaff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav p span {
  background: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px 1px #5b7fff2b;
}
p.activeNav {
  border-color: var(--primary-color-blue);
}

.searchInputWrapper {
  width: 100%;
  height: 36px;
  max-width: 330px;
  position: relative;
  height: 40px;
}
.searchInputWrapper svg {
  position: absolute;
  width: 18px;
  left: 12px;
  top: 12px;
}
.searchInput {
  padding-left: 2.5rem;
  padding-right: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}
.dropdownWrap {
  width: 140px;
}
.searchFilterWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.filterContainer {
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
  }
  .searchInputWrapper {
    width: 100%;
    max-width: unset;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 12px;
  }
  .searchInputWrapper {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

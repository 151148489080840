.deleteModal [class*="modal-dialog"] {
  height: 100vh;
  display: flex;
  align-items: center;
}
.content {
  box-shadow: 0 3px 11px rgb(255 86 48 / 20%);
}
.body {
  padding: 2rem;
  border-radius: inherit;
  color: #000;
  font-weight: 500;
}
.promptTxt {
  text-align: center;
  font-size: 16px;
  margin-bottom: 2rem;
}
.promptTxt span {
  color: #4e5d78;
}
.promptBtn {
  font-size: 14px;
  padding: 8px 2rem;
  border-radius: 8px;
  width: 49%;
  border: 1px solid #ff5630;
}
.promptBtn:first-of-type {
  margin-right: 2%;
  color: #ff5630;
  background-color: #fff;
}
.promptBtn:last-of-type {
  background: #ff5630;
  color: #fff;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-white);
  width: 100%;
}

.tableContainer .tableRow:nth-child(1) {
  width: 100%;
}
.tableRow {
  min-width: 800px;
  position: relative;
}

.viewBtn {
  background: #5b7fff;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 1rem;
}

.commentWrap {
  position: relative;
  text-align: right;
  padding-right: 1rem;
}
.commentWrap svg path {
  fill: var(--primary-color-blue);
}
.commentCount {
  position: absolute;
  top: -4px;
  right: 0;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #5b7fff;
  color: #fff;
  font-size: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  padding: 1px;
}
.commentCount:hover .commentCountDescrip {
  opacity: 1;
  visibility: visible;
}
.commentCountDescrip {
  position: absolute;
  width: max-content;
  background: #fff;
  top: -28px;
  color: #5b7fff;
  padding: 6px;
  border-radius: 20px;
  right: 0;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
  font-weight: 500;
  text-transform: lowercase;
  opacity: 0;
  visibility: hidden;
}

.statWrap [class*="caret_down_icon"] {
  right: 6px;
  width: 14px;
}
.statWrap [class*="caret_down_icon"] svg path {
  stroke: #4e5d78;
}

.wrapStatus {
  border: 1px solid #b0b7c3;
  font-weight: 600;
  text-transform: capitalize;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 13px;
  display: inline-block;
  padding-right: 18px;
  width: 94px;
}
.sent {
  color: #38cb89;
}
.review {
  color: #5b7fff;
}
.admission {
  color: #4b369d;
}
.submitted {
  color: #4e5d78;
}
.payable {
  color: #b0325e;
}
.rejected {
  color: #ff5630;
}
.accepted {
  color: #ffab00;
}

.universityWrap {
  display: flex;
  width: fit-content;
  align-items: center;
}
.universityWrap img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 8px;
  border: 1px solid #8a94a650;
  object-position: center;
}
.universityWrap .student {
  text-align: left;
}
.student {
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
}
.student div {
}
.student span:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  margin: auto 0;
  max-width: 250px;
}
.student span:last-child {
  color: var(--grey);
  margin-top: 4px;
  font-size: 13px;
  letter-spacing: 0.3px;
  opacity: 0.8;
}
.actionWrap {
  display: flex;
  align-items: center;
}
.editIcon {
  margin-right: 1rem;
  opacity: 0;
  width: 20px;
  height: 20px;
}
.editIcon path {
  fill: var(--primary-color-blue);
}
.tableRow:hover .editIcon {
  opacity: 1;
}
@media screen and (max-width: 1200px) {
  .student span:first-child {
    max-width: 160px;
  }
}

@media screen and (max-width: 1100px) {
  .student span:first-child {
    max-width: 145px;
  }
}

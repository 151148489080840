/* Table styles */

.tableWrapperClass {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.tableContainerClass {
  width: 100%;
}

.tableHeaderClass {
  min-width: 853px;
}

.tableHeaderItem {
  width: 33%;
  display: flex;
  align-items: center;
}

.emptyTableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  margin-top: 50px;
}

.emptyTableContainer .svg {
  width: 100px;
  height: fit-content;
  margin-bottom: 20px;
}

.loginWrapper {
  min-height: 100vh;
  background: #e5eaff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 1rem;
}
.logo {
  height: 60px;
}
.formWrapper {
  width: 550px;
  background: #fff;
  padding: 3rem 2.5rem;
  border-radius: 10px;
  margin-top: 2rem;
  text-align: center;
}
.formWrapper h1 {
  margin-bottom: 10px;
}
.formWrapper p {
  margin-bottom: 2.6rem;
}
.loginForm {
}
.inputWrap {
  margin-bottom: 1.5rem;
  position: relative;
}
.loginForm input {
  width: 100%;
  border: 1px solid #ccc;
  height: 40px;
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 2rem;
}
.inputWrap svg {
  position: absolute;
  top: 12px;
  width: 18px;
  right: 10px;
  cursor: pointer;
}
.loginForm button {
  background: #5b7fff;
  color: #fff;
  border: none;
  border-radius: 8px;
  margin: auto;
  padding: 12px 4rem;
}
[class*="recaptcha"] {
  margin-bottom: 2rem;
}
@media screen and (max-width: 600px) {
  .formWrapper {
    padding: 3rem 1.8rem;
    width: 100%;
  }
}

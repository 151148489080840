.title {
  margin-bottom: 2rem;
  font-size: 2rem;
  margin-top: 1rem;
}
.description {
  margin-bottom: 3rem;
  font-size: 16px;
  line-height: 1.6;
}
.count {
    margin-bottom: 1rem;
    font-weight: 600;
    text-align: right;
    text-decoration: underline;
    font-size: 1.5rem;
  }
@media screen and (max-width: 800px) {
  .title {
    font-size: 1.8rem;
    margin-top: 0;
  }
  .description {
    font-size: 15px;
  }
}

.prospectContainer {
}
.backBtn {
  color: var(--primary-color-blue);
  font-weight: 500;
  background: transparent;
  border: none;
  padding: 4px 0;
  margin-left: -4px;
  display: flex;
  align-items: center;
}
.backBtn svg {
  width: 22px;
  height: auto;
}
.backSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.backSec p {
  color: #121212;
  margin: 0;
}
.backSec p span {
  color: var(--grey);
  font-size: 12px;
  display: inline-block;
  word-break: break-all;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.createBtn {
  width: fit-content;
  padding: 6px 1rem;
}
.createBtn svg {
  margin-right: 6px;
  width: 20px;
}
.createBtn svg path {
  fill: var(--primary-color-blue);
}
.hd {
  font-size: 20px;
  font-weight: 600;
  color: var(--darkBlue);
  text-transform: uppercase;
  margin: 0;
}
.agentInfo {
  background: #fafbfc;
  border-radius: 12px;
  justify-content: space-between;
  display: flex;
  padding: 30px;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-template-areas:
    "a b"
    "a c"
    "a d"
    "a e"
    "a f";
  gap: 30px;
  margin-bottom: 4rem;
}
.name {
  color: var(--primary-color-blue);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  object-position: top;
  border: 1px solid #e1ecff;
  justify-content: center;
  display: block;
  margin-bottom: 1.2rem;
  margin-top: 1rem;
}
.data {
  font-size: 13px;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
}
.data label {
  width: 100px;
  font-size: 12px;
}
.data a {
  color: var(--primary-color-blue);
  text-decoration: underline;
  word-break: break-all;
  text-transform: lowercase;
  display: block;
}
.data p {
  color: #121212;
  word-break: break-all;
  text-transform: capitalize;
  margin: 0;
}
.personal {
  min-width: 300px;
  background: #fff;
  border-radius: inherit;
  padding: 1rem 1.5rem 8px;
  grid-area: a;
  height: fit-content;
  position: relative;
}
.ttl {
  color: #121212;
  font-weight: 500;
  margin-bottom: 1rem;
}
.suspendBtn {
  border-color: var(--red);
  color: #fff;
  width: fit-content;
  padding: 6px 1rem;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  background: transparent;
  color: var(--red);
  font-weight: 500;
}
.statusWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.status {
  font-size: 11px;
  margin-left: auto;
  padding: 6px 14px;
  color: var(--white);
  border-radius: 20px;
  height: fit-content;
  text-transform: capitalize;
}

.statusLabel {
  font-size: 12px;
  margin-right: 10px;
  color: #121212;
}
.contact {
  grid-area: b;
  background: #fff;
  border-radius: inherit;
  padding: 1.5rem;
}
.passport {
  grid-area: c;
  background: #fff;
  border-radius: inherit;
  padding: 1.5rem;
}
.university {
  grid-area: d;
  background: #fff;
  border-radius: inherit;
  padding: 1.5rem;
}
.source {
  grid-area: e;
  background: #fff;
  border-radius: inherit;
  padding: 1.5rem;
}
.info {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: space-between;
}
.info > div {
  width: 49.5%;
  display: flex;
  margin-bottom: 12px;
}
.info > div > label {
  min-width: 120px;
  max-width: 120px;
  font-size: 12px;
  padding-right: 4px;
}
.info > div > p {
  color: #121212;
  text-transform: capitalize;
  word-break: break-all;
  margin: 0;
}
.email {
  color: var(--primary-color-blue);
  text-decoration: underline;
  word-break: break-all;
  text-transform: lowercase;
}

.documents {
  background: #fff;
  border-radius: inherit;
  padding: 1.5rem;
  grid-area: f;
  position: relative;
}
.documentGroup {
  margin-bottom: 10px;
}
.documentGroup > p {
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 13px;
}
.documentList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.doc {
  margin-bottom: 1rem;
  padding: 8px 12px;
  background: #f7fbff;
  border-radius: 8px;
  width: fit-content;
  border: 1px solid var(--green);
  font-size: 13px;
  color: var(--green);
  align-items: center;
  display: flex;
  margin-right: 1rem;
  min-width: 190px;
}
.doc svg:first-of-type {
  margin-right: 10px;
  cursor: pointer;
}
.doc svg path {
  stroke: var(--green);
}
.doc .check {
  margin: 0;
  margin-left: 1rem;
  width: 22px;
}
.createBtn {
  width: fit-content;
  padding: 0px 4px;
  border: none;
  background: transparent;
  color: var(--primary-color-blue);
  text-decoration: underline;
}

.full {
  width: 100% !important;
}
.full label {
  width: unset !important;
}
/* .university .info > div {
    width: 100%;
  } */
/* .university .info label {
    min-width: 150px;
  } */

/* Dropdown */

.dropdownWrapper {
  margin-left: auto;
  display: block;
  width: fit-content;
  font-size: 12px;
}
.dropdown {
  padding: 4px 1rem;
  border-radius: 40px;
  color: #121212;
  color: var(--white);
  border: none;
  text-transform: capitalize;
}
.unenroled {
  color: var(--red);
  background: #ff56303d;
}
.pending {
  color: var(--pending);
  background: #ee943f47;
}
.enroled {
  color: #1a8547;
  background: #6fcf975e;
}
.dropdown .dropdownIcon {
  width: 12px;
  margin-left: 12px;
}
.dropdown .dropdownIcon path {
  fill: var(--white);
}
.unenroled .dropdownIcon path {
  fill: var(--red);
}
.pending .dropdownIcon path {
  fill: var(--pending);
}
.enroled .dropdownIcon path {
  fill: #1a8547;
}
.dropdownList {
  width: 120px;
  right: 0;
  text-transform: capitalize;
}
.dropdownListItem {
  padding-left: 20px;
}
.direct {
  max-width: unset !important;
}
.tagSec {
  margin-right: auto;
  margin-left: 10px;
  display: flex;
}
.tagSec div {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  position: relative;
}
.tagSec p {
  font-size: 12px;
  margin-bottom: 4px;
  margin-right: 4px;
  position: absolute;
  background: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  top: -34px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 15%);
  white-space: nowrap;
  display: none;
}
.tagSec div:hover p {
  display: block;
}
.tagSec > div > span {
  padding: 4px 12px;
  font-size: 12px;
  background: #ecf0ff;
  border-radius: 24px;
  color: #4f46e5;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  flex-wrap: wrap;
}

.tagSec1 span {
  margin-right: 10px;
}
.editBtn {
  color: var(--primary-color-blue);
  font-weight: 500;
  background: transparent;
  border: none;
  padding: 0;
}
.editBtn svg path {
  stroke: var(--primary-color-blue);
}
.btnSec {
  margin: 12px 0;
}
.btnSec button {
  margin-left: 1rem;
}
.editBtn .editBtn:hover {
  text-decoration: underline;
}
.tagTtl {
  display: none;
}
.updateBtn {
  color: #fff;
  background: var(--primary-color-blue);
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
}
.updateBtn svg {
  margin-right: 4px;
}
.updateBtn svg path {
  stroke: #fff;
}
.source .info,
.university .info {
  padding-top: 12px;
  padding-bottom: 1rem;
  border-bottom: 1px solid #8a94a620;
  margin-bottom: 1rem;
}
.source .info:last-child,
.university .info:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
}
.remApps {
  color: var(--primary-color-blue);
}
.prospectPlan {
  font-size: 12px;
  margin-top: -1rem;
  text-transform: uppercase;
}
.paymentStatus {
  padding: 4px 12px;
  font-size: 12px;
  background: #ecfff4;
  border-radius: 24px;
  color: var(--green);
  font-weight: 500;
  margin-left: auto;
  display: block;
  width: fit-content;
  position: absolute;
  right: 1rem;
  top: 2.2rem;
}

.viewComment {
  border-radius: 50%;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border: none;
  background: #fff;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-shadow: 0px 0px 5px 1px rgb(60 77 108 / 15%);
  background: var(--primary-color-blue);
  z-index: 2;
}
.viewComment svg path {
  fill: #fff;
}
.commentCount {
  border-radius: 50%;
  background: #fff;
  color: #3913d2;
  border: 1px dashed #3913d2;
  font-size: 82%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  position: absolute;
  right: -4px;
  top: -4px;
  padding: 1px;
}
.commentCount:hover .commentCountDescrip {
  opacity: 1;
}
.commentCountDescrip {
  position: absolute;
  width: max-content;
  background: #fff;
  top: -28px;
  color: #3913d2;
  padding: 4px 6px;
  border-radius: 20px;
  right: 0;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
  font-weight: 500;
  text-transform: lowercase;
  opacity: 0;
}

/* Table */
/* Header Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 30%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 27%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 18%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 15%;
  min-width: 60px;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(5),
.tableBodyClass:nth-child(5) {
  width: 10%;
}
.tableHeaderClass {
  min-width: 650px;
}
.tableWrapperClass {
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
}
.hdCTA {
  display: flex;
}
.dropdownWrap {
  white-space: nowrap;
  text-transform: capitalize;
}
.updatePaymentBtn {
  margin-right: 1rem;
  white-space: nowrap;;
  font-weight: 500;
  border: none;
  background: var(--primary-color-blue);
  color: #fff;
  border-radius: 6px;
  padding: 4px 12px;
  text-decoration: none;
}
.uploadDoc {
  transform: rotate(180deg);
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 50%;
  padding: 6px;
  width: 30px;
  height: 30px;
  background: #5b7fff;
}
.uploadDoc path {
  stroke: #fff;
  stroke-width: 2px;
}
.toggle {
  margin-bottom: 1rem;
}
.toggle > span {
  margin-left: 10px;
}
@media screen and (max-width: 1150px) {
  .info > div {
    min-width: 220px;
  }
  .agentInfo {
    grid-template-areas:
      "a b"
      "a c"
      "d d"
      "e e"
      "f f";
  }
  .agentInfo > div {
    height: 100%;
  }
}

@media screen and (max-width: 950px) {
  .general {
    flex-direction: column;
  }
  .data {
    display: flex;
  }
  .data label {
    width: 110px;
    font-size: 12px;
  }
  .info > div {
    flex-direction: column;
  }
  .agentInfo {
    grid-template-areas: "a" "b" "c" "d" "e" "f";
    grid-template-columns: 1fr;
  }
  .personal {
    padding: 20px;
    min-width: unset;
  }
  .documents {
    padding: 20px;
  }
  .agentInfo {
    gap: 1rem;
    padding: 1rem;
  }
  .heading {
    flex-wrap: wrap;
  }
  .hd {
    width: 100%;
    margin-bottom: 1rem;
  }
  .toggle {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
  }
  .tagSec {
    flex-direction: column;
  }
  .tagSec > div > span {
    font-weight: 400;
  }
  .tagSec div:hover p {
    display: none;
  }
  .btnSec {
    margin-bottom: 0;
  }
  .prospectContainer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .tagTtl {
    margin-right: 6px;
    padding: 0;
    width: fit-content;
  }
  .btnSec button {
    font-size: 13px;
  }
  .updateBtn {
    padding: 6px 10px;
  }
}
@media screen and (max-width: 400px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
  }
  .status {
    margin-top: 10px;
  }
}

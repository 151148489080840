.tableContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-white);
  width: 100%;
}

.tableRow {
  min-width: 650px;
}

.tableContainer .tableRow:nth-child(1) {
  width: 100%;
}

.tableRowItem:first-of-type {
  width: 60%;
}
.viewBtn {
  background: #5b7fff;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 1rem;
}
.viewBtn {
  background: #5b7fff;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 1rem;
}
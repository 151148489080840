/* Table Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 4%;
  text-transform: capitalize;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 20%;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(5),
.tableBodyClass:nth-child(5) {
  width: 20%;
  min-width: 60px;
  padding-right: 10px;
}
.tableHeaderItem:nth-child(6),
.tableBodyClass:nth-child(6) {
  width: 10%;
}
.tableHeaderItem:nth-child(7),
.tableBodyClass:nth-child(7) {
  width: 6%;
  align-items: flex-end;
}

.tableWrapperClass {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.tableContainerClass {
  width: 100%;
}

.tableHeaderClass {
  min-width: 853px;
}

.tableHeaderItem,
.tableBodyClass {
  display: flex;
  align-items: center;
}

.searchInputWrapper {
  width: 100%;
  height: 36px;
  max-width: 330px;
  position: relative;
  height: 40px;
}
.searchInputWrapper svg {
  position: absolute;
  width: 18px;
  left: 12px;
  top: 12px;
}
.searchInput {
  padding-left: 2.5rem;
  padding-right: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}
.dropdownWrap {
  width: 140px;
}
.searchFilterWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.filterContainer {
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
  }
  .searchInputWrapper {
    width: 100%;
    max-width: unset;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 12px;
  }
  .searchInputWrapper {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

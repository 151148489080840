/* Header Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 15%;
  text-transform: capitalize;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 30%;
  text-transform: capitalize;
  padding-right: 1rem;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 30%;
  padding-right: 1rem;
  word-break: break-all;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 10%;
}
.tableWrapperClass {
  overflow-x: hidden;
  width: 100%;
  overflow-x: auto;
}
.tableHeaderClass, .tableContainer {
  min-width: 800px;
}

.createBlog {
  background: #5b7fff;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  padding: 5px 1.2rem;
  margin-bottom: 1rem;
}
.uniLogoContainerImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uniLogoContainerImage input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.uniLogoContainerImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  max-width: 300px;
  height: 200px;
  margin: 20px 0px 20px 0px;
  cursor: pointer;
}

.cameraIcon {
  width: 50px;
  height: 50px;
}

.verificationIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  width: 20px;
  height: 20px;
}

.tickIcon {
  fill: #34f734;
}

.warningIcon {
  fill: yellow;
  stroke: red;
}
.formContainer {
  display: flex;
  flex-wrap: wrap;
}
.formContainer > div {
  display: flex;
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  flex-direction: column;
  position: relative;
}
.formContainer label {
  font-weight: 500;
  margin-bottom: 4px;
}
.formContainer input {
  height: 40px;
  padding-left: 10px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #e5e5e5;
}
.formContainer textarea {
  height: 150px;
  padding-left: 10px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #e5e5e5;
  padding-top: 10px;
  max-height: 150px;
}
.formButton {
  background: #5b7fff;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  padding: 5px 1.2rem;
  margin-bottom: 3rem;
}

.formButton:disabled {
  background: rgb(105, 105, 105);
  cursor: not-allowed;
}

.message {
  margin: 15px 0px;
}

.message svg {
  width: 16px;
  height: 16px;
  vertical-align: bottom;
  fill: red;
}

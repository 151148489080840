.tableBody {
  min-width: 650px;
}
.tableRow {
  text-transform: capitalize;
}
.agent {
  display: flex;
  align-items: center;
  margin: auto;
  display: inline-flex;
}
.agent div {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.agent span {
  display: block;
}
.agent .agencyName {
  font-size: 11px;
  max-width: 120px;
  text-overflow: ellipsis;
  display: inline-block;
  color: var(--grey);
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}
.agent img {
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  object-position: top;
  border: 1px solid #e1ecff;
  width: 30px;
  height: 30px;
  justify-content: center;
  margin-right: 8px;
}
.student {
  display: flex;
  flex-direction: column;
  width: fit-content;
  /* margin: auto; */
  text-align: left;
}
.student div {
}
.student span:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  display: inline-block;
  margin: auto 0;
  text-transform: capitalize;
}
.student span:nth-child(2) {
  color: var(--grey);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.appID {
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}
.btn {
  padding: 6px 1rem;
  font-size: 12px;
  border-radius: 4px;
}
.eye {
  cursor: pointer;
  width: 24px;
  height: auto;
  outline: none;
}
.idWrap {
  display: flex;
  justify-content: center;
}
.idWrap svg {
  cursor: pointer;
  width: 20px;
  height: auto;
}
.universityWrap {
  display: flex;
  /* margin: auto; */
  width: fit-content;
}
.universityWrap img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
  border: 1px solid #e1ecff50;
  object-position: center;
}
.universityWrap .student {
  text-align: left;
}

.commentCount {
  position: relative;
  border-radius: 50%;
  background: var(--primary-color-blue);
  color: #fff;
  font-size: 80%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  top: -10px;
}
.commentCount:hover .commentCountDescrip {
  opacity: 1;
}
.commentCountDescrip {
  position: absolute;
  width: max-content;
  background: #fff;
  top: -28px;
  color: var(--primary-color-blue);
  padding: 4px 6px;
  border-radius: 20px;
  right: 0;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
  font-weight: 500;
  text-transform: lowercase;
  opacity: 0;
}
.viewCommentIcon path {
  fill: var(--primary-color-blue);
}
@media screen and (max-width: 900px) {
  .hideOnMobile {
    display: none;
  }
  .hideOnDesktop {
    display: block;
  }
  .agent .agentName {
    color: #4e5d78;
    font-weight: 700;
    text-transform: capitalize;
  }
  .agent .agencyName {
    max-width: 220px;
  }
  .agent {
    margin-bottom: 1rem;
    width: 100%;
  }
  .agent img {
    width: 50px;
    height: 50px;
    margin-right: 14px;
  }
  .appID {
    max-width: 220px;
  }
  .commentCount {
    top: -4px;
  }
  .menuBtn {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .agent .agencyName {
    max-width: 50vw;
  }
  .appID {
    max-width: 50vw;
  }
}
